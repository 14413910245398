import React, {useState} from "react";
import '/src/assets/css/RegisterDomains/register-domains-intro.css'
import globeImg from '/src/assets/images/register-domains/globe.png'
import Feedback from "../Feedback/Feedback";
import axios from "axios";
import {Link} from "gatsby";

const RegisterDomainsIntro = () => {


    const whmcsUrl = 'https://client.sitebunker.net/includes/api.php'
    const username = 'hMnIRhPjfy8s0o2TYHdgAHDAX5yQ4lVC'
    const password = 'vFIlXlkxLWW6ocAcY6L3l6p1QEsyznRg'
    const accessKey = 'SQAwpa3gpNty5vhw'
    const actionType = 'DomainWhois'


    const [activat, setActivat] = useState(false)
    const [available, setAvailable] = useState(null)
    const [inchide, setInchide] = useState(false)
    const [domeniu, setDomeniu] = useState("")
    const [loading, setLoading] = useState(false)


    const onGetData = () => {
        setLoading(true)
        let inputValue = document.querySelector("input").value
        axios.post(whmcsUrl, null, {
            params: {
                username: username,
                password: password,
                accesskey: accessKey,
                action: actionType,
                responsetype: 'json',
                domain: inputValue.includes('www.') ? inputValue.slice(4) : inputValue
            }
        }).then(response => {
            if (response.data.result === 'success') {
                setDomeniu(inputValue.includes('www.') ? inputValue.slice(4) : inputValue)
                setActivat(true)
                setAvailable(response.data.status)
                setLoading(false)
            } else {
                setLoading(false)
                setActivat(false)
            }

        }).catch(error => {
            console.log(error)
        })

    }

    const onClose = (valoare) => {
        setActivat(valoare)
    }

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            onGetData()
        }
    }


    return (
        <section id='register-domains-intro'>
            <div className="container">
                <div className="row position-relative mb-5">
                    <div className="col-md-7 col-12">
                        <h2 className='h2-title'>Domain registration</h2>
                        <p>No matter you are looking for .COM or .EU domains, at SiteBunker.net you will find multiple
                            available extensions for your preferred domain name. In addition to domain registration, you
                            can choose web hosting subscriptions, especially designed for any needs.</p>
                    </div>

                    <img src={globeImg} alt="Register Domains" className='register-d-img' loading="lazy"/>

                </div>

                <div className="prices-container">

                    {/*Prices*/}
                    <div className="prices-info">
                        <div className='d-flex align-items-center mr-3'>
                            <span className='dot'>.</span>
                            <span className='domain'>eu</span>
                            <span className='mt-1 ml-2 price'>- $3,01</span>
                        </div>
                        <div className='d-flex align-items-center mr-3'>
                            <span className='dot'>.</span>
                            <span className='domain'>net</span>
                            <span className='mt-1 ml-2 price'>- $11,85</span>
                        </div>
                        <div className='d-flex align-items-center mr-3'>
                            <span className='dot'>.</span>
                            <span className='domain'>com</span>
                            <span className='mt-1 ml-2 price'>- $12.99</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='dot'>.</span>
                            <span className='domain'>org</span>
                            <span className='mt-1 ml-2 price'>- $13,86</span>
                        </div>
                    </div>


                    {/*Input*/}
                    <div className="d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control search-domain"
                                   placeholder="Enter the preferred domain name here..."
                                   onKeyPress={handleKeypress}
                                   pattern={'^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$'}/>
                            <div className="input-group-append ">
                                <button className="search-btn" type="button" onClick={(e) => onGetData(e)}>
                                    <span className='d-md-inline-block d-none'>Search</span>
                                    <i className='d-md-none d-inline-block flaticon-right font-weight-bolder'></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/*Feedback Message*/}
                    <div className="d-flex mt-2">
                        {loading ? <div className="spinner-border text-info" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : null}
                        {activat && !loading ?
                            <Feedback domeniu={domeniu} inchide={onClose} stare={inchide} available={available}/>
                            : null}
                    </div>

                    {/*Sub Input*/}
                    <div className="d-flex flex-row domains-links">
                        <Link to='/transfer-domains'>Transfer a domain</Link>
                        <span className='pl-4 pr-4'>|</span>
                        <a href='https://client.sitebunker.net/cart.php?a=add&domain=register&language=english&currency=3'
                           target='_blank'>Price list</a>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default RegisterDomainsIntro
