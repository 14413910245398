import React from "react";

import visaLogo from '/src/assets/images/payments/visa-logo.png'
import expressLogo from '/src/assets/images/payments/american-express.png'
import masterCardLogo from '/src/assets/images/payments/master-card.png'
import maestroLogo from '/src/assets/images/payments/maestro.png'
import payLogo from '/src/assets/images/payments/paypal.png'

import '/src/assets/css/Common/payments-accepted.css'

const PaymentsAccepted = () => {
    return (
        <section id='payments-accepted'>
            <div className="row">
                <div className="col-12 text-center">
                    <p className='text-white mb-4'>Accepted payment methods</p>
                </div>
            </div>

            <div className="payment-logos-container">
                <img src={visaLogo} alt="Visa" className='payment-logo mb-3' loading="lazy"/>
                <img src={maestroLogo} alt="Maestro" className='payment-logo mb-3' loading="lazy"/>
                <img src={expressLogo} alt="Express" className='payment-logo mb-3' loading="lazy"/>
                <img src={masterCardLogo} alt="Master" className='payment-logo mb-3' loading="lazy"/>
                <img src={payLogo} alt="PayPal" className='payment-logo mb-3' loading="lazy"/>
            </div>
        </section>
    )
}

export default PaymentsAccepted