import React from "react";
import '/src/assets/css/RegisterDomains/domain-tips.css'
import domainImg from '/src/assets/images/register-domains/domain-search.png'
import oneImg from '/src/assets/images/register-domains/1.png'
import twoImg from '/src/assets/images/register-domains/2.png'
import threeImg from '/src/assets/images/register-domains/3.png'
import fourImg from '/src/assets/images/register-domains/4.png'

const DomainTips = () => {
    return (
        <section id='domain-tips'>
            <div className="container">
                <div className="row">

                    <div className="col-12 text-center mb-5">
                        <h3 className='h3-title main-color'>Tips & tricks - how to choose the right name for your
                            domain</h3>
                    </div>

                    <div className="col-lg-5 d-lg-flex col-12 d-none">
                        <div className="d-flex h-100 align-items-center">
                            <img src={domainImg} alt="Domain Tips" className='domain-img img-fluid' loading="lazy"/>
                        </div>
                    </div>

                    <div className="col-lg-7 col-12">

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={oneImg} alt="Unu" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>In you want your target to easily remember the name of your site,
                                choose one that is short and easy to write, so that it can be typed quickly in any
                                Google search.</p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={twoImg} alt="Doi" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Choose a representative TLD. If you are focused on the world wide
                                market, we recommend you the .COM extension or go to .EU if you want more european
                                visitors.
                            </p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={threeImg} alt="Trei" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Avoid numbers, or words written differently than normal to remove
                                confusion and situations where your visitors can’t easily type your domain name.
                            </p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={fourImg} alt="Patru" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Choose a unique domain name, without considering Exact Match key
                                phrases (ex: women-shoes.com). This is no longer a SEO advantage for search engines.</p>
                        </div>

                        <p>Need more tips&tricks to choose the perfect domain name? SiteBunker.net domain registration specialists are at your disposal 24/7, through
                            <a href="https://client.sitebunker.net/submitticket.php"
                               className='ml-2 font-weight-bolder' target="_blank">the ticketing system available here.</a>. </p>


                    </div>

                </div>
            </div>
        </section>
    )
}

export default DomainTips