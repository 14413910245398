import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import BenefitInfo from "../components/Common/BenefitInfo";
import PaymentsAccepted from "../components/Common/PaymentsAccepted";
import RegisterDomainsIntro from "../components/RegisterDomains/RegisterDomainsIntro";
import DomainTips from "../components/RegisterDomains/DomainTips";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import SEO from "../components/App/SEO";
import '/src/assets/css/RegisterDomains/register-domains-benefits.css'

// SVG
import SvgSearch from "/src/assets/svgComponents/search.svg"
import SvgRedirect from "/src/assets/svgComponents/redirect.svg"
import SvgInfinite from "/src/assets/svgComponents/inifinite.svg"
import SvgFile from "/src/assets/svgComponents/file-text.svg"
import SvgServerRefresh from "/src/assets/svgComponents/server-refresh.svg"
import SvgLock from "/src/assets/svgComponents/lock.svg"
import SvgRefresh from "/src/assets/svgComponents/refresh.svg"


const faqs = [
    {
        title: 'What is the TLD meaning?',
        text1: 'TLD or Top Level Domain is the extension chosen for your domain. Basically it refers to the last characters of the domain. For example, in the domain SiteBunker.net, ".net" represents the TLD or extension. You can choose from over 50 TLD possibilities and we recommend that you do this depending on the market you are targeting (Ex: For the international market, .COM is more suitable than .EU)',
    },
    {
        title: 'What are the differences between domain and hosting?',
        text1: 'The difference between the domain name and the web hosting subscription is: ' +
            '1. The domain is the address of an Internet location that helps users access certain websites ' +
            '2. The hosting is basically the physical space in which the content of a website is saved and published to allow access to by using the Internet.',
    },
    {
        title: 'Can I buy a domain without purchasing a web hosting subscription?',
        text1: 'Purchasing a domain does not automatically require you to purchase also a web hosting subscription. At SiteBunker.net you can do as many domain registrations as you want and host them either with us or with any other favorite provider.',
    },
    {
        title: 'How long does it take to register a domain?',
        text1: 'In most cases, registering and activating a domain is almost instant. As soon as you have completed the order process, including payment has been confirmed and you have entered all the data required for registration, your domain will be active.',
    },
    {
        title: 'How do I transfer a domain to SiteBunker.net?',
        text1: 'In order to transfer to SiteBunker.net any domain purchased from other provider, it is necessary to obtain the Transfer Authorization Key. With this info, the transfer can be done in just a few seconds.',
    },
    {
        title: 'What is DNS?',
        text1: 'DNS or "domain name system" is the standard used to manage the IP addresses of all web sites around the world. When you access a domain in any browser, it queries the DNS server and instantly displays the content.',
    },
    {
        title: 'Can I change the domain name after I buy it?',
        text1: 'Once purchased, a domain name cannot be changed. However, you can choose to purchase a new domain name, and the original one will not be extended after the expiration date. Don\'t forget that you can also redirect the original domain to the new domain, if you do not want to lose any visits made by users already used with the old domain.',
    },
    {
        title: 'What if I forget to renew my domain name?',
        text1: 'Depending on the purchased availability, a domain requires renewal at a certain interval (Ex: 1 year). If for various reasons you dind’t managed to renew it, remember that there is a grace period available (30-90 days), depending on the chosen extension. In this grace period, you can still recover the domain, but an extra recovery fee will apply. If you exceeded the grace period, the domain will become available for a different potential buyer and will no longer be your property.',
    },
    {
        title: 'What exactly does Whois Privacy mean?',
        text1: 'When you choose to purchase Whois Privacy, you are making sure that all personal information present in the public WHOIS database of your website is secure and cannot be used by hackers and/or spammers in various malicious actions.',
    }
]

const RegisterDomains = () => {
    return (

        <Layout>

            {/*SEO*/}
            <SEO title="Domain registration and web hosting at affordable prices."
                 description="Are you looking for COM, EU or NET domain registration? Discover the available extension and the web hosting subscriptions available at SiteBunker.net."
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Register Domains Intro*/}
            <RegisterDomainsIntro/>

            {/*Register Benefits Section*/}
            <section id='register-domains-benefits'>
                <div className="container">
                    <div className="register-header text-center">
                        <h3 className='h3-title main-color mb-0'>Reasons why you should choose SiteBunker.net
                            services</h3>
                        <p className='main-color mb-0'>At SiteBunker.ne you have multiple extensions available for
                            domain registration.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12">

                            <BenefitInfo svgIcon={<SvgSearch/>}
                                         title='More than 50 extensions'
                                         description='Multiple choices are available to choose your favorite extension, no matter you are looking for classic domain such as .COM or newly released .PRO or .CLOUD.'/>
                        </div>
                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgInfinite/>}
                                         title='Advanced search available'
                                         description='With the help of the advanced search engine, you can identify your preferred domain name and extension in just a few clicks.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgFile/>}
                                         title='Unlimited subdomains'
                                         description='You can create unlimited subdomains for any landing page you plan to dedicate to your online campaign, blog, or online shop.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgServerRefresh/>}
                                         title='Easy to use control panel'
                                         description='You will be able to easily manage all your domain or subdomains settings with the easy-to-use control panel offered by SiteBunker.net.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgRedirect/>}
                                         title='Simple redirect process'
                                         description='With Sitebunker.net is easy to redirect any domain to another domain or website throughthe available web hosting services.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgLock/>}
                                         title='Free Domain Lock'
                                         description='Rest assured, your domain is safe! We prevent accidental or unauthorized transfers as long as you domain is registred at SiteBunker.net.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgRefresh/>}
                                         title='Flexibility for renewal'
                                         description='You can choose between automatic or manual renewal. Regardless of your preference, we make sure you receive all notifications on time.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgFile/>}
                                         title='Personal data & privacy '
                                         description='Forget about hackers and spammers with Whois Privacy, available at affordable costs. Thus, personal data will be safe from any attack.'/>
                        </div>
                    </div>

                </div>
            </section>

            {/*Accepted Payments*/}
            <PaymentsAccepted/>

            {/*Domain Tips*/}
            <DomainTips/>

            {/*Domains FAQS*/}
            <SimpleFAQS title='We are to assist you for any question'
                        subTitle='Read the below answers for the most frequently asked questions about domain registration or contact the SiteBunker.net team.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>

    )
}

export default RegisterDomains